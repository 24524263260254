import React, { useEffect, useState } from 'react'
import {
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import AppHeading from 'components/AppHeading'
import { useTranslation } from 'react-i18next'
import SEO from 'components/Seo'
import { JPGuidelines, ENGuidelines } from 'config/constants/guideline'
import { pageView } from 'utils/tracker'
import { TRACKING, LANG_JP } from 'config/constants/common'
import './index.scss'

export default function Guideline() {
  const { t, i18n } = useTranslation('guideline')
  const { language: currentLang } = i18n
  const matchMobie = useMediaQuery('(max-width: 640px)')
  const [guidelineContent, setGuidelineContent] = useState(
    currentLang === LANG_JP ? JPGuidelines : ENGuidelines
  )

  useEffect(() => {
    pageView(trackingEventSource)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setGuidelineContent(currentLang === LANG_JP ? JPGuidelines : ENGuidelines)
  }, [currentLang])

  const trackingEventSource = TRACKING.SOURCE.GUIDELINE

  return (
    <div id="guideline-view" className={'Layout-children'}>
      <SEO title={t('lbl_title_en')} />
      <AppHeading title={t('lbl_title_en')} />
      <Container className="guideline-container">
        <div id="guidelineJP">
          <p>
            <img
              src={guidelineContent.welcome.img}
              alt={guidelineContent.welcome.img}
              style={{ width: '100%', height: 'auto' }}
            />
          </p>

          <div className="guidelineJP-participation">
            <h4 className="guideline-title">{guidelineContent.participation.header}</h4>
            {guidelineContent.participation.listContentItems.map((element, index) => (
              <p
                className="guideline-txt guidelineJP-participation-txt"
                key={'jpparticipation' + index}
              >
                {element}
              </p>
            ))}
          </div>

          <div className="guidelineJP-list">
            {guidelineContent.list.map((element, index) => {
              const renderedItem = []
              if (index === guidelineContent.otherthings.position) {
                renderedItem.push(
                  <p key={'guidelineJP-otherthings'} className="guidelineJP-otherthings">
                    {guidelineContent.otherthings.label}
                  </p>
                )
              }
              renderedItem.push(
                <div className="guideline-admg-item" key={`jpguide-${index}`}>
                  <h5 className="guideline-admg-item-serial">
                    {index < 9 ? `0${index + 1}` : index + 1}
                  </h5>

                  <div className="guideline-admg-item-content">
                    <p
                      className="guideline-txt guidelineJP-txt"
                      style={{ whiteSpace: 'pre-wrap', marginBottom: 0 }}
                      dangerouslySetInnerHTML={{ __html: element }}
                    />
                  </div>
                </div>
              )
              return renderedItem
            })}
          </div>
          {matchMobie ? (
            <div id="subsidiaries-mobile">
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p className="subsidiaries-title">
                    {currentLang === LANG_JP
                      ? guidelineContent.subsidiaries.heading
                      : guidelineContent.subsidiaries.subHeadingA.main}
                  </p>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="domestic-subsidiaries">
                    {currentLang !== LANG_JP && (
                      <p
                        className="subsidiaries-item"
                        style={{ fontWeight: 'normal', marginBottom: 24 }}
                      >
                        {guidelineContent.subsidiaries.subHeadingA.content}
                      </p>
                    )}
                    {guidelineContent.subsidiaries.list1.map((element, index) => (
                      <p key={`list1-${index}`}>{element}</p>
                    ))}
                    {currentLang === LANG_JP &&
                      guidelineContent.subsidiaries.list2.map((element, index) => (
                        <p key={`list2-${index}`}>{element}</p>
                      ))}
                  </div>
                </AccordionDetails>
              </Accordion>
              {currentLang !== LANG_JP && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p className="subsidiaries-title">
                      {guidelineContent.subsidiaries.subHeadingB.main}
                    </p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="domestic-subsidiaries">
                      <p
                        className="subsidiaries-item"
                        style={{ fontWeight: 'normal', marginBottom: 24 }}
                      >
                        {guidelineContent.subsidiaries.subHeadingB.content}
                      </p>
                      {guidelineContent.subsidiaries.list2.map((element, index) => (
                        <p key={`list2-${index}`}>{element}</p>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}
            </div>
          ) : (
            <div>
              {currentLang === LANG_JP ? (
                <div id="subsidiaries">
                  <div className="domestic-subsidiaries">
                    <p className="subsidiaries-title">{guidelineContent.subsidiaries.heading}</p>
                    {guidelineContent.subsidiaries.list1.map((element, index) => (
                      <p key={`ds-${index}`} className="subsidiaries-item">
                        {element}
                      </p>
                    ))}
                  </div>

                  <div className="overseas-subsidiaries">
                    <div className="subsidiaries-title" style={{ paddingBottom: 30 }} />
                    {guidelineContent.subsidiaries.list2.map((element, index) => (
                      <p key={`os-${index}`} className="subsidiaries-item">
                        {element}
                      </p>
                    ))}
                  </div>
                </div>
              ) : (
                <div id="subsidiaries" style={{ flexDirection: 'column' }}>
                  <h4 className="guideline-title">{guidelineContent.subsidiaries.heading}</h4>
                  <div className="domestic-subsidiaries" style={{ width: '100%' }}>
                    <div className="guideline-admg-item" style={{ marginBottom: 0 }}>
                      <h5 className="guideline-admg-item-serial">
                        {guidelineContent.subsidiaries.subHeadingA.no + '.'}
                      </h5>

                      <div>
                        <p className="subsidiaries-title">
                          {guidelineContent.subsidiaries.subHeadingA.main}
                          <br />
                          <span className="subsidiaries-item" style={{ fontWeight: 'normal' }}>
                            {guidelineContent.subsidiaries.subHeadingA.content}
                          </span>
                        </p>

                        {guidelineContent.subsidiaries.list1.map((element, index) => (
                          <p key={`ds-${index}`} className="subsidiaries-item">
                            {element}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="overseas-subsidiaries" style={{ marginTop: 60, width: '100%' }}>
                    <div className="guideline-admg-item" style={{ marginBottom: 0 }}>
                      <h5 className="guideline-admg-item-serial">
                        {guidelineContent.subsidiaries.subHeadingB.no + '.'}
                      </h5>

                      <div>
                        <p className="subsidiaries-title">
                          {guidelineContent.subsidiaries.subHeadingB.main}
                          <br />
                          <span className="subsidiaries-item" style={{ fontWeight: 'normal' }}>
                            {guidelineContent.subsidiaries.subHeadingB.content}
                          </span>
                        </p>

                        {guidelineContent.subsidiaries.list2.map((element, index) => (
                          <p key={`os-${index}`} className="subsidiaries-item">
                            {element}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          <p className="guideline-txt guidelineJP-bottom">{JPGuidelines.bottom}</p>
        </div>
      </Container>
    </div>
  )
}
