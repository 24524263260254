import imgGuideline1 from 'assets/images/guideline1.jpg'

/* eslint-disable max-len */
export const ListAdmissionGuidelines = [
  'Please be sure to show your employee ID at the hall reception desk . Due to security concerns, those who do not have an employee ID are not allowed to enter.',
  'On - site workers are not allowed to enter.',
  'Please bring two business cards if you are a target company and do not have an employee ID system',
  'In the hall(hall building, seminar hall), please wear an employee ID card so that you can always see it, and insert the Open House admissi card into your chest',
  'If you are not able to respond to the above, please contact us so that we can speak to you',
  'Explanations by the staff will be in Japanese and English.Any time zone is available',
  'Shooting is prohibited in all areas in the venue.You cannot eat or drink',
  'If you are not a member of ASTRO and want to attend the seminar, you need to apply for admission in advance.For more information, please click her',
]

export const AdmissionLimitation =
  'Since we handle non-public information, we have limited the companies that can be visited. In Japan, only the company that has submitted the consent form" is allowed to enter the "NEC Group Confidential Information".\nPlease refer to this for overseas group companies.\nFor submission of consent form, please check the website of the Legal Department (trade secret management → subsidiary list) (As of June 2019)'

export const DomesticSubsidiaries = [
  'NEC',
  'PS Communication Planning Co., Ltd.',
  'NEC Solution Innovator Co., Ltd.',
  'Nippon Telecommunications System Co., Ltd.',
  'Infosec Corporation',
  'OCC Corporation',
  'NEC Display Solutions Co., Ltd.',
  'NEC Nexa Solutions Co., Ltd.',
  'Shimizu Shintech Co., Ltd.',
  'KIS Co., Ltd.',
  '-KIS Dot Eye Co., Ltd.',
  'Sunnet Co., Ltd.',
  'WC Solutions Co., Ltd.',
  'Bestcom Solutions Co., Ltd.',
  'NEC Network Sensor Co., Ltd.',
  '-Daiwa Electronics Service Co., Ltd.',
  'NEC Aerospace Systems Co., Ltd.',
  'NEC Space Technology Co., Ltd.',
  'Cyber ​​Defense Research Institute Co., Ltd.',
  'Japan Earth Observation Satellite Service Co., Ltd.',
  'ABeam Consulting Co., Ltd.',
  '-ABeam Systems Co., Ltd.',
  'Forward Integration System Service Co., Ltd.',
  'Revans Net Co., Ltd.',
  'Breeze Corporation',
  'JN System Partners Co., Ltd.',
  'NEC Nets S Co., Ltd.',
  '-NEC Nets S Service Co.,',
  'Ltd.-Nichiwa Co., Ltd.-',
  'Q & A Co., Ltd.-',
  'Q & A Works Co., Ltd.-',
  'D Cubic Co., Ltd.-',
  'NEC Magnus Communications Co., Ltd.-',
  'NEC Net Innovation Co., Ltd.-',
  'Language One Co., Ltd.-',
  'K & N System Integrations Co., Ltd.-',
  'Nets Innovation Venture Limited Liability Partnership		',
  'NEC Platforms Co., Ltd.',
  '- (stock) NTT NTT',
  '- Shizuoka NICHIDEN Business Co., Ltd.',
  'NEC Embedded Products Co., Ltd.',
  '-NEC Embedded Technology Co., Ltd.',
  'NEC Fielding Co., Ltd.',
  '-Ndec Co., Ltd.-',
  'NEC Fielding Support Crew Co., Ltd.-',
  'NEC Fielding System Technology Co., Ltd.',
  'International Institute of Social and Economic Research',
  'NEC Livex Co., Ltd.',
  'NEC Management Partner Co., Ltd.',
  '-NEC VALWAY Co., Ltd.',
]

export const OverseasSubsidiaries = [
  'NEC Laboratories America',
  'NEC Laboratories Europe',
  'NEC Laboratories China',
  'NEC Asia Pacific Pte. Ltd.',
  'NEC Australia (NEC Australia Pty. Ltd.)',
  'NEC Latin America SA',
  'NEC Mexico (NEC de Mexico, SA de CV)',
  'NEC Canada (NEC Canada, Inc.)',
  'NEC Deutschland GmbH',
  'NEC UK (NEC (UK) Ltd.)',
  'NEC France SAS',
  'NEC Scandinavia AB',
  'NEC Iberica SL',
  'NEC CORPORATION (THAILAND) LIMITED',
  'NEC Hong Kong Limited',
  'NEC Taiwan Ltd.',
  'NEC Platform Technologies Hong Kong Limited',
  'NEC Italia spa',
  'NEC Indonesia (PT. NEC INDONESIA)',
  'NEC Europe Ltd.',
  'Nidec Excellence Software (Beijing) Co., Ltd.',
  'NEC Philippines (NEC Philippines, Inc.)',
  'Nichiden Communication Co., Ltd.',
  'Nichiden (China) Co., Ltd.',
  'NEC Neva Communications Systems',
  'NEC Telecom MODUS, Ltd.',
  'NEC New Zealand Limited',
  'NEC Telecom Software Philippines, Inc.',
  'NEC Display Solutions of America, Inc.',
  'NEC Display Solutions Europe GmbH',
  'NEC Portugal',
  'NEC Platforms Thai Co., Ltd',
  'NEC Financial Services (NEC Financial Services, LLC.)',
  'NEC Capital UK (NEC Capital (UK) plc)',
  'NEC Corporation of America',
  'NEC Corporation of Malaysia Sdn. Bhd.',
  'NEC Technologies India (LTD.)',
  'NEC Nederland BV',
  'NEC Vietnam (NEC Vietnam Co., Ltd)',
  'NEC Software System Technology (Hangzhou) Co., Ltd.',
  'NEC Enterprise Communications Technologies, Inc.',
  'NEC Soft (Jinan) Co., Ltd.',
  'NEC Eastern Europe Kft.',
  'NEC Turkey (NEC Telecommunication and Information Technology Ltd.)',
  'NEC Africa (NEC Africa (Pty) Ltd.)',
  'NEC (NEC Corporation of Korea)',
  'NEC Columbia (NEC de Colombia SA)',
  'NEC Chile SA',
  'NEC Argentina SA',
  'NEC (Chongqing) Information System',
  'NECAM Israel Research Center',
  'NEC Saudi Arabia',
  'NEC Laboratories Europe Spain',
  'NEC X, Inc.',
]

export const urls = {
  lst1: 'http://www.ipc.nec.co.jp/legal/kaiseikigyouhimitsu.html',
  lst2: 'https://idm-info.auth.dir.nec.co.jp/jp/fe_gac_fcw.html',
}

export const JPGuidelines = {
  welcome: {
    header: 'ようこそ、OpenHouse2020へ',
    listContentItems: [
      '4月に研究・開発ユニットを設立し、技術の強み拡大はそのままに迅速に事業に繋げるべく体制強化しました。',
      'この年のチャレンジとしてOpenHouseでの共創の場を「リアル」から「オンライン」へ移しました。',
      '集まる・深まる・繋がるをコンセプトワードにDigital×Designで実現する新しいコミュニケーションモデル。',
      'この新たな「場」を今後のコミュニケーションに活かし、新しい「何か」が生まれることを期待します。',
    ],
    img: imgGuideline1,
  },
  participation: {
    header: 'OpenHouse2020参加にあたって',
    listContentItems: [
      'OpenHouseはオンラインへ移行したことで、いつでも、どこからでもコンテンツにアクセスして、先端の研究開発成果を理解把握できるようになりました。',
      'OpenHouse2020へ参加するにあたってはいくつかの「きまり」があります。',
    ],
  },
  list: [
    `コンテンツ情報開示に当たっては、「ＮＥＣグループ外秘事項の取扱に関する同意書」を法務部へ提出した会社に所属する正社員であることが条件です。\n派遣、請負社員の方はご参加いただけません。詳しくは、<a href=${urls.lst1} target="_blank" rel="noreferrer">企業秘密管理規程について</a>（法務部ホームページ）`,
    `Webサイトへアクセスするためには「ＮＥＣ-Gアカウント（for クラウド）」による認証が必要です。\n詳しくは、<a href=${urls.lst2} target="_blank" rel="noreferrer">ＮＥＣ-Gアカウント（for クラウド）とは</a>`,
    'コンテンツを出展者に無断でダウンロード、コピー（画面のスクリーンショットを含む）、転載することはご遠慮ください。\nインターネット（社外SNS等）への二次発信（拡散）もできません。\n出展者に事前の断り無く、発表資料や顧客提案にコンテンツを流用した場合、研究開発や事業の活動に支障が出る可能性があるほか、著作権等の問題によりコンプライアンス違反が発生するおそれもあります。上記が守られない場合は、 ＮＥＣグループ行動規範及び従業員就業規則等に基づき、懲戒などの処罰を受ける可能性があります。',
    '快適にコンテンツ（特に、セミナーや動画など）へアクセスするためには、社有携帯端末（iPhone、iPadなど）からキャリア回線を通じてアクセスいただくことをお勧めします。',
    'Webサイトはメンテナンス時間（毎日19:00-20:00）を除き終日アクセス可能です。',
    '各展示の Q&Aセッションは原則、会期中の9:00-17:00のみの開催となります。また、一部の展示（海外拠点からの出展等）は対応時間が異なります。各展示ページにてご確認ください。',
    'Slido掲示板（Q&A掲示板）はシステム上、個人情報がアイルランドのサーバーを経由します。ご了承の上でご利用ください。',
    '展示コンテンツの内容や今後の共創に向けた相談については、直接各部門へ問い合わせください。',
    '各コンテンツは一部を除き、展示会終了後も閲覧可能とする予定です。後日、アナウンスします。',
  ],
  otherthings: {
    position: 3,
    label: 'その他、知っておいていただきたいこと',
  },
  subsidiaries: {
    heading: 'アクセス可能企業一覧',
    subHeadingA: {
      no: '',
      main: '',
      content: '',
    },
    list1: [
      'ＮＥＣソリューションイノベータ㈱',
      '日本電気通信システム㈱',
      '㈱オーシーシー',
      'ＮＥＣネクサソリューションズ㈱',
      '㈱ＫＩＳ',
      '㈱ワイイーシーソリューションズ',
      'ＮＥＣネットワーク・センサ㈱',
      '-大和電子サービス㈱',
      '日本電気航空宇宙システム㈱',
      'NECスペーステクノロジー㈱',
      'フォワード・インテグレーション・システム・サービス㈱',
      'リバンスネット㈱',
      'JNシステムパートナーズ㈱',
      'ＮＥＣネッツエスアイ㈱',
      '-ＮＥＣネッツエスアイ・サービス㈱',
      '-㈱ニチワ',
      '-ＮＥＣマグナスコミュニケーションズ㈱',
      '-NECネットイノベーション㈱',
      '-Ｋ＆Ｎシステムインテグレーションズ(株)',
      'ＮＥＣプラットフォームズ㈱',
      '-静岡日電ビジネス㈱',
      'ＮＥＣエンベデッドプロダクツ㈱',
      '-ＮＥＣエンベデッドテクノロジー㈱',
      'ＮＥＣフィールディング㈱',
      '-ＮＥＣフィールディングサポートクルー㈱',
      '-ＮＥＣフィールディングシステムテクノロジー㈱',
      '㈱国際社会経済研究所',
      '㈱ＮＥＣライベックス　',
      'ＮＥＣマネジメントパートナー㈱',
      '-ＮＥＣ　ＶＡＬＷＡＹ㈱',
      '㈱日本電気特許技術情報センター',
      'ＮＥＣフレンドリースタフ㈱',
      '㈱高砂製作所',
      'ＮＥＣファシリティーズ㈱',
      '-㈱近畿分析センター',
    ],
    subHeadingB: {
      no: '',
      main: '',
      content: '',
    },
    list2: [
      'NEC Asia Pacific Pte. Ltd.',
      'NEC Australia Pty. Ltd.',
      'NEC Corporation (Thailand) Ltd.',
      'PT.NEC Indonesia',
      'NEC Philippines,Inc.',
      'NEC New Zealand Limited',
      'NEC Telecom Software Philippines,Inc.',
      'NEC Platforms Thai Co.,Ltd.',
      'NEC Corporation of Malaysia Sdn. Bhd.',
      'NEC Technologies India Private Limited',
      'NEC Vietnam Co., Ltd.',
      'NEC Hong Kong Limited',
      'NEC Taiwan Ltd.',
      'NEC Platform Technologies Hong Kong Limited',
      '日電通信有限公司',
      '日電(中国)有限公司',
      'NEC飛鼎克信息技術服務(北京)有限公司',
      'NEC軟件(済南)有限公司',
      'NEC Corporation of Korea',
      'NEC(重慶)信息系統有限公司',
      'ＮＥＣ　中国研究院',
      'NEC Deutschland GmbH',
      'NEC (UK) Ltd.',
      'NEC France S.A.S.',
      'NEC Scandinavia AB',
      'NEC Iberica S.L.',
      'NEC Italia s.p.a.',
      'NEC Europe Ltd.',
      'Joint Stock Company NEC Neva Communications Systems',
      'NEC Telecom MODUS,Ltd.',
      'NEC Laboratories Europe GmbH',
      'NEC Portugal-Telecomunicacoes e Sistemas,S.A.',
      'NEC Capital (UK) plc',
      'NEC Nederland B.V.',
      'NEC Eastern Europe Kft.',
      'NEC Telecommunication and Information Technology Ltd.',
      'NEC Africa (Pty) Ltd. ',
      'NEC Saudi Arabia, Ltd.',
      'XON Systems (Pty) Ltd. An NEC Company',
      'NEC XON Systems Namibia (Pty) Ltd.',
      'NEC XON Consulting (Pty) Ltd. ',
      'NEC XON Retail Solutions (Pty) Ltd.',
      'NEC XON Business Solutions (Pty) Ltd.',
      'NEC XON Alternative Energy (Pty) Ltd.',
      'NEC XON Coastal (Pty) Ltd.',
      'NEC Latin America S.A.',
      'NEC de Mexico, S.A. de C.V.',
      'NEC de Colombia S.A.',
      'NEC Chile S.A.',
      'NEC Argentina, S.A.',
      'NEC Canada,Inc.',
      'NEC Financial Services,LLC.',
      'NEC Corporation of America',
      'NEC Laboratories America,Inc.',
      'NEC Enterprise Communication Technologies, Inc.',
      'NEC X, Inc.',
    ],
  },
  bottom: 'OpenHouse2020運営事務局',
}

export const ENGuidelines = {
  welcome: {
    img: imgGuideline1,
  },
  participation: {
    header: 'OpenHouse2020 Participation Guidelines',
    listContentItems: [
      'Because OpenHouse will be taking place online, it will be possible to access the content and gain an understanding of the latest R&D developments anytime and from anywhere. There are several requirements and guidelines for participating in OpenHouse2020.',
    ],
  },
  list: [
    `In order to view the content, participants must be full-time employees of companies which have submitted a “Letter Regarding the Treatment of Information within the NEC Group” to the Legal Department.Agency workers and contract employees are not eligible for participation. For details, see <a href=${urls.lst1} target="_blank" rel="noreferrer">“ Regulations concerning the proper protection of trade secrets ”</a> (Legal Department website)`,
    `To access the website, an “NEC-G account (for cloud)” will be required for authentication.\nFor details, see <a href=${urls.lst2} target="_blank" rel="noreferrer">“ What is NEC-G Account (for cloud)? ”</a>`,
    'Participants are not allowed to download, copy (including the taking of screenshots), or reproduce any content without the consent of the exhibitor.\nParticipants are also not allowed to share such materials on the Internet (e.g. third party social media).\nInappropriate use of the content, such as for presentations and proposals to clients, without prior consent of the exhibitor can not only hamper business and research and development, but also cause intellectual property issues which in turn can lead to compliance violations. Failure to comply with the above can lead to disciplinary actions in accordance with the NEC Group Code of Conduct, Company Rules for Employees, etc.',
    'The use of company-issued mobile devices (e.g. iPhone, iPad, etc.) and service by a major wireless carrier is highly recommended for smooth access to the content (particularly seminars and videos).',
    'The website can be accessed throughout the day except during maintenance (JST 19:00 - 20:00 of every day).',
    'In general, Q&A sessions for exhibits will only be available from JST 9:00 to 17:00 during the event. Additionally, the hours will vary for some exhibits (such as those held from outside of Japan). Please check the web pages for exhibits.',
    'Please note that due to the way Slido (Q&A platform) works, personal information will be routed through servers in Ireland.',
    'For details regarding the content of exhibits and for co-creation discussions, please contact the relevant departments directly.',
    'Most content for the event will be available even after the event ends. Details will be announced at a later date.',
  ],
  otherthings: {
    position: 3,
    label: 'Additional useful information',
  },
  subsidiaries: {
    heading: 'List of NEC Group Companies Invited (outside Japan)',
    subHeadingA: {
      no: 'A',
      main: 'Companies using GIDM data linkage',
      content:
        'The employee of the following companies are registered to the OpenHouse2020 Web site except for Slido. Additional registration is needed for using Slido.',
    },
    list1: [
      'NEC Asia Pacific Pte. Ltd.',
      'NEC Australia Pty. Ltd.',
      'NEC Corporation(Thailand)Ltd.',
      'PT.NEC Indonesia',
      'NEC Philippines,Inc.',
      'NEC Platforms Thai Co.,Ltd.',
      'NEC Corporation India Private Limited',
      'NEC Vietnam Co., Ltd.',
      'NEC Taiwan Ltd.',
      '日電通信有限公司',
      '日電(中国)有限公司',
      'NEC軟件(済南)有限公司',
      'NEC Corporation of Korea',
      'NEC(重慶)信息系統有限公司',
      'NEC de Mexico, S.A. de C.V.',
      'NEC de Colombia S.A.',
      'NEC Corporation of America',
      'NEC Laboratories America,Inc.',
      'NEC Enterprise Communication Technologies, Inc.',
    ],
    subHeadingB: {
      no: 'B',
      main: 'Companies not using GIDM data linkage',
      content:
        'The employee of the following companies needs registration prior to access to the OpenHouse2020 Web site including Slido.',
    },
    list2: [
      'NEC New Zealand Limited',
      'NEC Telecom Software Philippines,Inc.',
      'NEC Corporation of Malaysia Sdn. Bhd.',
      'NEC Hong Kong Limited',
      'NEC Platform Technologies Hong Kong Limited',
      'NEC飛鼎克信息技術服務(北京)有限公司',
      'NEC Deutschland GmbH',
      'NEC (UK) Ltd.',
      'NEC France S.A.S.',
      'NEC Scandinavia AB',
      'NEC Iberica S.L.',
      'NEC Italia s.p.a.',
      'NEC Europe Ltd.',
      'Joint Stock Company NEC Neva Communications Systems',
      'NEC Telecom MODUS,Ltd.',
      'NEC Display Solutions Europe GmbH',
      'NEC Portugal-Telecomunicacoes e Sistemas,S.A.',
      'NEC Capital (UK) plc',
      'NEC Nederland B.V.',
      'NEC Eastern Europe Kft.',
      'NEC Telecommunication and Information Technology Ltd.',
      'NEC Africa (Pty) Ltd.',
      'NEC Saudi Arabia',
      'XON Systems (Pty) Ltd. An NEC Company',
      'NEC XON Systems Namibia (Pty) Ltd.',
      'NEC XON Consulting (Pty) Ltd. ',
      'NEC XON Retail Solutions (Pty) Ltd.',
      'NEC XON Alternative Energy (Pty) Ltd.',
      'NEC Latin America S.A.',
      'NEC Chile S.A.',
      'NEC Argentina, S.A.',
      'NEC Canada,Inc.',
      'NEC Display Solutions of America, Inc.',
      'NEC Financial Services,LLC.',
      'NEC X, Inc.',
      'NEC Laboratories Europe GmbH',
    ],
  },
  bottom: 'OpenHouse2020運営事務局',
}
